import { z } from "zod";

import { getEnvVariable } from "~shared/get-env-variable.ts";

export const DEVICES_TIMEZONE = "Europe/Berlin";

export type Alert = {
	severity: "error" | "warning" | "info" | "success";
	message: string;
};

export enum AssignedDevicesType {
	"NO_DEVICES" = "NO_DEVICES",
	"MANY_DEVICES" = "MANY_DEVICES",
	"ONE_AIR_CUBE" = "ONE_AIR_CUBE",
	"ONE_HEAT_CUBE" = "ONE_HEAT_CUBE",
}

export const autocompleteArray = (arrayValidators: z.ZodArray<any>) => {
	return z
		.string()
		.transform((val) => {
			return val === "" ? [] : val.split(",");
		})
		.pipe(arrayValidators);
};

export const routesWithoutAuth = new Set([
	"/login",
	"/login/activate-account",
	"/login/forgot-password",
	"/login/reset-password",
	"/fair-demo",
	"/devices-links",
]);

export const ZIndex = {
	base: "z-10",
	menu: "z-20",
};

export const MAX_DEVICES_CREATED_TOGETHER = 50;
export const MAX_CHANGELOG_LENGTH = 200;

export const ACTIVATE_ACCOUNT_ERROR_MESSAGES: Record<string, string> = {
	NotAuthorizedException: "wrongActivationCode",
	LimitExceededException: "toManyTriesTryAgainLater",
	ExpiredCodeException: "resetLinkExpired",
	TooManyFailedAttemptsException: "toManyTriesTryAgainLater",
	TooManyRequestsException: "toManyTriesTryAgainLater",
	InternalFailure: "noInternetConnection",
	ServiceUnavailable: "serviceUnavailableTryAgainLater",
};

export const CHANGE_PASSWORD_MESSAGES: Record<string, string> = {
	...ACTIVATE_ACCOUNT_ERROR_MESSAGES,
	NotAuthorizedException: "wrongCurrentPassword",
};

export const LOGIN_ERROR_MESSAGES: Record<string, string> = {
	...ACTIVATE_ACCOUNT_ERROR_MESSAGES,
	NotAuthorizedException: "wrongEmailOrPassword",
};

export const ENTRY_META_CONFIG = {
	dsn: getEnvVariable("SENTRY_DSN"),
	environment: getEnvVariable("DEPLOY_ENV"),
	release: getEnvVariable("LATEST_COMMIT_SHA"),
	denyUrls: [
		/\/build\//,
		/\/favicons\//,
		/\/images\//,
		/\/fonts\//,
		/\/favicon.png/,
		/\/site\.webmanifest/,
	],
	enabled:
		getEnvVariable("DEPLOY_ENV") != "local" &&
		getEnvVariable("NODE_ENV") != "test",
};
export const DEMO_ACCOUNT_EMAIL = "demo@smartcube360.de";
